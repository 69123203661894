import React, { useState, useEffect, useReducer } from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Masonry from 'react-masonry-component'
import Img from 'gatsby-image'

import { WhoQuery } from '../../graphql-types'
import styled from 'styled-components'
import { Content } from '../components/styled'
import { DEFAULT_SIZE, BLACK, MOBILE_BREAKPOINT } from '../Utils'

const BlogIndex = ({
  data,
  location,
}: {
  data: WhoQuery
  location: Location
}) => {
  const siteTitle = get(data, 'site.siteMetadata.title')
  const node = data.allContentfulWhoWeWorkWith.edges[0].node

  return (
    <Layout location={location}>
      <Content>
        <div></div>
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} />
          <Styles>
            <div>
              <span>Architects:</span>
              <br />
              <br />
              {node.architects.architects.split('\n').map((a) => (
                <p style={{ color: BLACK, margin: '0rem 0 1rem 0' }}>{a}</p>
              ))}
            </div>
            <div>
              <span>Clients:</span>
              <br />
              <br />
              {node.clients.clients.split('\n').map((a) => (
                <p style={{ color: BLACK, margin: '0rem 0 1rem 0' }}>{a}</p>
              ))}
            </div>
          </Styles>
        </div>
      </Content>
    </Layout>
  )
}

const Styles = styled.div`
  display: flex;
  text-align: left;
  line-height: 1.3;

  span {
    display: block;
  }

  div {
    width: 50%;
    padding: 0.2rem;
  }
`

export default BlogIndex

export const pageQuery = graphql`
  query Who {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulWhoWeWorkWith {
      edges {
        node {
          # description {
          #   childMarkdownRemark {
          #     html
          #   }
          # }
          architects {
            architects
          }
          clients {
            clients
          }
        }
      }
    }
  }
`
